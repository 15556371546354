<template>
    <div class="frequently-asked-questions">
        <div class="banner">
            <div class="banner-text">Frequently Asked Questions</div>
            <b-img src="@/assets/banners/faq_banner.jpg"/>
        </div>
        <b-row class="home-content">
            <b-col>
                Below you will find answers to the questions we get asked the most about
                Superintendent Career Training. If you did not find the answers you were
                looking for here, please <router-link :to="{name:'contact'}">contact us</router-link> directly.
            </b-col>
        </b-row>
        <b-row class="home-content clearfix pic-button">
            <b-col>
                <b-card-group deck>
                    <b-card class="about-card" title="Superintendent" img-src="@/assets/sections/faq_superintendent.jpg" @click="picClicked('superintendent')"/>
                    <b-card class="about-card" title="Mentors" img-src="@/assets/sections/faq_mentors.jpg" @click="picClicked('mentors')"/>
                    <b-card class="about-card" title="Contractors" img-src="@/assets/sections/faq_contractors.jpg" @click="picClicked('contractors')"/>
                </b-card-group>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <b-button variant="link" @click="expandAll">Expand All</b-button>
                <b-button variant="link" @click="collapseAll">Collapse All</b-button>
            </b-col>
        </b-row>
        <about-section ref="superintendent"
                      section="faq-superintendent"
                      class="home-content"
                      title="Superintendent"
                      :visible="superintendentVisible"
                      v-on:section-clicked="superintendentVisible = !superintendentVisible">
            <template v-for="(question, i) in superintendentQuestions">
                <div>
                    <b-link @click="question.visible = !question.visible" class="m-2">{{question.question}}</b-link>
                    <b-collapse class="m-2" v-model="question.visible" :id="'faq-' + i">
                        <b-card>
                            <div class="card-text" v-html="question.answer"></div>
                        </b-card>
                    </b-collapse>
                </div>
            </template>
        </about-section>
        <about-section ref="mentors"
                      section="faq-mentors"
                      class="home-content"
                      title="Mentors"
                      :visible="mentorsVisible"
                      v-on:section-clicked="mentorsVisible = !mentorsVisible">
            <template v-for="(question, i) in mentorsQuestions">
                <div>
                    <b-link @click="question.visible = !question.visible" class="m-2">{{question.question}}</b-link>
                    <b-collapse class="m-2" v-model="question.visible" :id="'faq-' + i">
                        <b-card>
                            <div class="card-text" v-html="question.answer"></div>
                        </b-card>
                    </b-collapse>
                </div>
            </template>
        </about-section>
        <about-section ref="contractors"
                      section="faq-contractors"
                      class="home-content"
                      title="Contractors"
                      :visible="contractorsVisible"
                      v-on:section-clicked="contractorsVisible = !contractorsVisible">
            <template v-for="(question, i) in contractorQuestions">
                <div>
                    <b-link @click="question.visible = !question.visible" class="m-2">{{question.question}}</b-link>
                    <b-collapse class="m-2" v-model="question.visible" :id="'faq-' + i">
                        <b-card>
                            <div class="card-text" v-html="question.answer"></div>
                        </b-card>
                    </b-collapse>
                </div>
            </template>
        </about-section>
    </div>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator';
import AboutSection from '@/components/public/AboutSection.vue';
import {PicClicked} from '@/util/pic_clicked';

@Component({

    mixins: [PicClicked],

    components: {
        AboutSection
    }
})
export default class FAQ extends Vue {

    superintendentQuestions = [{
        visible: false,
        question: 'Why should I participate in this training course?',
        answer: `The Carpenters International Training Fund (CITF) Superintendent Career Training offers you a
                 unique opportunity to build skills in executing daily tasks and prepare yourself for advancement.
                 You will partner with a mentor and participate in on-the-job training to supplement your learning
                 experience along with classroom exposure to technical and management principles required for
                 successful field supervision. The combination of these components makes the CITF's Superintendent
                 Career Training Program unique and greatly enhances your chances of success.`
    }, {
        visible: false,
        question: 'What will it cost me?',
        answer: `Your time and commitment throughout the 18-month program! The Carpenters International Training
                 Fund (CITF) will pay for rooms and meals during your training at the ITC, airfare when booked
                 through the CITF travel coordinator, registration fees, and associated class costs.`
    }, {
        visible: false,
        question: 'How do I know if I have what it takes to be a good superintendent candidate?',
        answer: `<p>
                    Be honest with yourself and evaluate your own personality and skills against the following
                    criteria for good superintendent candidates:
                </p>
                <ul>
                    <li>A good craftsman with knowledge of the different work processes used in the construction industry</li>
                    <li>Good communication skills</li>
                    <li>Inherent leadership qualities</li>
                    <li>Willing to learn new concepts</li>
                    <li>Basic computer skills</li>
                    <li>Good interpersonal skills</li>
                    <li>Basic management skills or the willingness to learn them</li>
                    <li>Ability to visualize or mentally formulate work processes before they are implemented</li>
                </ul>`
    }, {
        visible: false,
        question: 'Do I have to work for a union contractor to participate in SCT?',
        answer: 'Yes.'
    }, {
        visible: false,
        question: 'Do I have to stay with the same union contractor throughout SCT?',
        answer: 'Yes.'
    }, {
        visible: false,
        question: 'What if I switch contractors after I am enrolled into SCT?',
        answer: `You must promptly notify the Carpenters International Training Fund (CITF) if your employer
                 changes. You will have to reapply to SCT and will be required to start SCT from the beginning,
                 repeating any previously completed programs with your new signatory contractor and new Mentor.`
    }, {
        visible: false,
        question: 'When and where are training tracks held?',
        answer: `<p>
                    The SCT Program includes four sessions; one session approximately every six months, taking
                    about a year and a half to complete. Courses are held at the International Training Center
                    (ITC) in Las Vegas on Thursday through Saturday. The mentors attend a two-day workshop
                    during Program 1 and 3, on Friday and Saturday. After that, all mentoring is done on the job.
                 </p>
                 <p>
                    This 18-month program includes multiple entry points during the calendar year. Participants
                    can begin in February, May, June, July, October, or December.
                 </p>
                 <ol>
                    <li>The Feb class meets Feb – July – Feb – July</li>
                    <li>The May class meets May – Oct – May – Oct</li>
                    <li>The June class meets June – Dec – June – Dec</li>
                 </ol>`
    }, {
        visible: false,
        question: 'What are my responsibilities outside of the training tracks?',
        answer: `Study your material and ask questions. Listen to your mentor and take every opportunity to learn
                 from your mentor and remain committed to the process. 30 OJT's and 15 MA's are also required.`
    }, {
        visible: false,
        question: 'Why do I need a mentor?',
        answer: `Through the program, your mentor plans, coordinates, and assures that you will receive a
                 meaningful experience. You will meet with your mentor once each month through the 18-month
                 program for one-on-one interaction, discussion, and instruction on a selected topic focused
                 on the company's specific approach, strategy, policies, and procedures.`
    }, {
        visible: false,
        question: 'What do I do if my mentor is no longer employed by my contractor or is no longer able to mentor?',
        answer: `You must promptly notify the Carpenters International Training Fund (CITF) if your Mentor is no
                 longer employed by the company or is no longer available to mentor you. The signatory contractor
                 must then select a new Mentor from the company to assist you with completing the remaining
                 portions of SCT.`
    }, {
        visible: false,
        question: 'How is the OJT program structured?',
        answer: `The mentor and superintendent-in-training select a minimum of 30 specific on-the-job-training
                 experiences that are determined to best fit the needs of the company and the trainee. These OJT
                 experiences are completed during the 18-month program.`
    }, {
        visible: false,
        question: 'Who provides the on-site program instruction?',
        answer: `The SCT faculty is from collegiate construction education programs. Their extensive industry work
                 experience and expertise spans positions as superintendents, managers, executives, and registered
                 engineers.`
    }, {
        visible: false,
        question: `How do I know that what I'm learning is what I need to help my company?`,
        answer: `Mentors and trainees are responsible for building on the basic knowledge provided in the four,
                 three-day programs at the ITC. Customization to your specific company is accomplished through
                 the on-the-job training and mentoring experiences selected by your mentor and
                 superintendent-in-training.`
    }];

    mentorsQuestions = [{
        visible: false,
        question: 'Why should I participate in this training course?',
        answer: `The Carpenters International Training Fund (CITF) Superintendent Career Training offers you a
                 unique opportunity to use your expertise and experience to help educate a field superintendent.
                 Participation in this program as a mentor will be rewarding to you as an individual and will also
                 help your company and ultimately the construction industry.`
    }, {
        visible: false,
        question: 'What will it cost me?',
        answer: `<p>
                    Your time, expertise, experience, and commitment to your trainee/s throughout the 18- month
                    program are the only costs to the mentor.
                 </p>
                 <p>
                    The Carpenter's International Training Fund (CITF)
                    will pay for rooms and meals during your training at the ITC, airfare when booked through the
                    CITF travel coordinator, registration fees, and associated class costs.
                 </p>`
    }, {
        visible: false,
        question: 'How do I know if I have what it takes to be a good mentor?',
        answer: `Anyone who is experienced as a superintendent or project manager qualifies as a mentor. Good
                 communication skills and a willingness to be a mentor are necessary for success.`
    }, {
        visible: false,
        question: 'Do I have to work for a union contractor to participate in SCT?',
        answer: 'Yes.'
    }, {
        visible: false,
        question: 'Do I have to stay with the same union contractor throughout SCT?',
        answer: 'Yes.'
    }, {
        visible: false,
        question: 'What if I switch contractors after my trainee is enrolled into SCT?',
        answer: `You must promptly notify the Carpenters International Training Fund (CITF) if your employer
                 changes. The sponsoring contractor will have to choose a new mentor for any trainee/s you
                 mentored for the trainee/s to complete the program.`
    }, {
        visible: false,
        question: 'Who selects the superintendent trainee and mentor?',
        answer: `The sponsoring contractor selects both the superintendent trainee and the mentor. Trainees must
                 be members in good standing of the United Brotherhood of Carpenters.`
    }, {
        visible: false,
        question: 'Where and when are training tracks held?',
        answer: `<p>
                    The SCT Program includes four sessions; one session approximately every six months, taking
                    about a year and a half to complete. Courses are held at the International Training Center
                    (ITC) in Las Vegas on Thursday through Saturday. The mentors attend a two-day workshop during
                    Program 1 and 3, on Friday and Saturday. After that, all mentoring is done on the job.
                 </p>
                 <p>
                    This 18-month program includes multiple entry points during the calendar year. Participants
                    can begin in February, May, June, July, October, or December.
                 </p>
                 <ol>
                    <li>The Feb class meets Feb – July – Feb – July</li>
                    <li>The May class meets May – Oct – May – Oct</li>
                    <li>The June class meets June – Dec – June – Dec</li>
                 </ol>`
    }, {
        visible: false,
        question: 'What are my responsibilities outside of the workshop?',
        answer: `It's important that you take a proactive attitude in coordinating SCT activities, providing
                 structured on-the-job-training experiences, and conduct your one-on-one sessions with the trainee.`
    }, {
        visible: false,
        question: 'Why does a trainee need a mentor?',
        answer: `Through the program, the mentor is the person who plans, coordinates, and assures that the trainee
                 receives a meaningful experience. Mentors meet with trainees once each month through the 18-month
                 program for one-on-one interaction, discussion, and instruction on a selected topic focused on the
                 company's specific approach, strategy, policies, and procedures.`
    }, {
        visible: false,
        question: 'How is the OJT program structured?',
        answer: `The mentor and superintendent-in-training select a minimum of 30 specific on-the-job- training
                 experiences that are determined to best fit the needs of the company and the trainee. These OJT
                 experiences are completed during the 18-month program.`
    }, {
        visible: false,
        question: 'How do I know that what our trainee is learning is what I need to help my company?',
        answer: `Mentors and trainees are responsible for building on the basic knowledge provided in the four,
                 three-day programs at the ITC. Customization to your specific company is accomplished through the
                 on-the-job training and mentoring <strong>experiences</strong> selected by your mentor and
                 superintendent-in-training.`
    }];

    contractorQuestions = [{
        visible: false,
        question: 'Why should my company participate in this training course?',
        answer: `The Carpenters International Training Fund (CITF) Superintendent Career Training is unique from
                 other available programs. You select individuals from your company exhibiting potential for
                 advancement and offer them this opportunity. Along with classroom exposure to technical and
                 management principles required for successful field supervision, the program offers on-the-job
                 training for your candidate under the guidance of a mentor in a customizable and flexible
                 format.`
    }, {
        visible: false,
        question: 'What will it cost my company?',
        answer: `<p>
                    Commitment and support for the mentor and their trainee throughout the 18-month program as the
                    mentor coordinates SCT activities, provides structured on-the-job-training experiences, and
                    advocates the mentoring one-on-one sessions with the trainee (or participant?).
                 </p>
                 <p>
                    The Carpenters International Training Fund (CITF) pays for airfare, rooms, meals, registration
                    fees and associated class costs during trainee's participation in Programs 1 thru 4 and the
                    Mentor's participation in Programs 1 &amp; 3 with their trainee/s.
                 </p>`
    }, {
        visible: false,
        question: 'How do I choose a good superintendent candidate to enter the program?',
        answer: `<p>Look for someone who:</p>
                 <ul>
                    <li>
                        is a good craftsman with knowledge of the different work processes used in the construction
                        industry
                    </li>
                    <li>has good communication skills</li>
                    <li>has inherent leadership qualities</li>
                    <li>is willing to learn new concepts</li>
                    <li>has basic computer skills</li>
                    <li>has good interpersonal skills</li>
                    <li>has basic management skills or the willingness to learn basic management skills</li>
                    <li>can visualize or mentally formulate work processes before they are implemented</li>
                 </ul>`
    }, {
        visible: false,
        question: 'Do I have to be a union contractor to participate in SCT?',
        answer: 'Yes.'
    }, {
        visible: false,
        question: 'Why should my company participate in this training course?',
        answer: `The Carpenters International Training Fund (CITF) Superintendent Career Training is unique from
                 other available programs. You select individuals from your company exhibiting potential for
                 advancement and offer them this opportunity. Along with classroom exposure to technical and
                 management principles required for successful field supervision, the program offers on-the-job
                 training for your candidate under the guidance of a mentor in a customizable and flexible
                 format.`
    }, {
        visible: false,
        question: 'Who selects the superintendent trainee and mentor?',
        answer: `As the sponsoring contractor, you select both the superintendent trainee and the mentor. Trainees
                 must be members in good standing of the United Brotherhood of Carpenters.`
    }, {
        visible: false,
        question: 'Who in my company would make a good mentor?',
        answer: `Anyone who is experienced as a superintendent or project manager qualifies as a mentor. Good
                 communication skills and a willingness to be a mentor are necessary for success.`
    }, {
        visible: false,
        question: 'When and where are training tracks held?',
        answer: `<p>
                    The SCT Program includes four sessions; one session approximately every six months, taking
                    about a year and a half to complete. Courses are held at the International Training Center
                    (ITC) in Las Vegas on Thursday through Saturday. The mentors attend a two-day workshop during
                    Program 1 and 3, on Friday and Saturday. After that, all mentoring is done on the job.
                 </p>
                 <p>
                    This 18-month program includes multiple entry points during the calendar year. Participants
                    can begin in February, May, June, July, October, or December.
                 </p>
                 <ol>
                    <li>The Feb class meets Feb – July – Feb – July</li>
                    <li>The May class meets May – Oct – May – Oct</li>
                    <li>The June class meets June – Dec – June – Dec</li>
                 </ol>`
    }, {
        visible: false,
        question: 'Why are mentors needed?',
        answer: `Through the program, the mentor is the person who plans, coordinates, and assures that the
                 trainee receives a meaningful experience. Mentors meet with trainees once each month through
                 the 18-month program for one-on-one interaction, discussion, and instruction on a selected topic
                 focused on the company's specific approach, strategy, policies, and procedures.`
    }, {
        visible: false,
        question: 'How is the OJT program structured?',
        answer: `The mentor and superintendent-in-training select a minimum of 30 specific on-the-job-training
                 experiences that are determined to best fit the needs of the company and the trainee. These OJT
                 experiences are completed during the 18-month program.`
    }, {
        visible: false,
        question: 'Who provides the on-site program instruction?',
        answer: `The SCT faculty is from collegiate construction education programs. Their extensive industry
                 work experience and expertise spans positions as superintendents, managers, executives, and
                 registered engineers.`
    }, {
        visible: false,
        question: 'How do I know that what my trainee is learning is what I need for my company?',
        answer: `Mentors and trainees are responsible for building on the basic knowledge provided in the four,
                 three-day programs at the CITC. Customization to your specific company is accomplished through
                 the on-the-job training and mentoring experiences selected by your mentor and
                 superintendent-in-training.`
    }];

    superintendentVisible = false;
    mentorsVisible = false;
    contractorsVisible = false;



    expandAll() {
        this.superintendentVisible = true;
        this.mentorsVisible = true;
        this.contractorsVisible = true;
    }

    collapseAll() {
        this.superintendentVisible = false;
        this.mentorsVisible = false;
        this.contractorsVisible = false;
    }
}
</script>
<style scoped>

</style>
